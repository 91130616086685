


































































































































































import { Component, Vue, Ref, Prop, PropSync } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { MapMarker } from "@/types/mapMarker";
import { TempPoint } from "@/types/TempPoint";
//  components
import Map from "@/components/Map.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import FoulingModule from "@/store/clients/Fouling.module";

const Fouling = getModule(FoulingModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    Map,
  },
})
export default class OperationalProfileCard extends Vue {
  @Ref("Map") Map!: any;
  @Prop({ default: false }) isModal!: boolean;
  @PropSync("dateRange", { required: true }) syncDateRange!: string[];

  syncSpeedLog = false;
  isFromDateModalActive = false;
  isToDateModalActive = false;
  invalidRange = false;
  currentDate: string = moment().format("YYYY-MM-DD");
  fromDate: string = moment().startOf("year").format("YYYY-MM-DD");
  toDate: string = moment().format("YYYY-MM-DD");
  min = moment(moment().year(this.speedHistoryStartYear).startOf("year").format("YYYY-MM-DD")).valueOf();
  max = moment(moment().format("YYYY-MM-DD")).valueOf();
  interval: any = moment.duration(1, "days").asMilliseconds();
  seaTemperatureDateRange!: { from: string; to: string };

  // @Getters
  get vessel(): ExtendedVessel | null {
    return Vessels.currentVessel;
  }

  get getSeaTemperatureDateRange(): { from: string; to: string } {
    return this.seaTemperatureDateRange;
  }

  get getSeaTemperaturePoints(): TempPoint[] {
    return Fouling.seaSurfaceTemperature;
  }

  get hoursInWarmWater(): string {
    if (!this.getSeaTemperaturePoints.length) return "N/A";

    var total = 0;
    this.getSeaTemperaturePoints.forEach((point, i) => {
      if (point.temperature >= 25 && point.timeSpent) {
        total += point.timeSpent;
      }
    });

    return `${Math.round(total)} hours`;
  }

  get speedHistoryStartYear(): number {
    return Number(moment(Fouling.speedHistoryStartYear).format("YYYY"));
  }

  get formattedSelectedDateRange(): { from: string; to: string } {
    return {
      from: moment(this.syncDateRange[0]).format("DD. MMM, YYYY"),
      to: moment(this.syncDateRange[1]).format("DD. MMM, YYYY"),
    };
  }

  get mapHeight(): string {
    return this.isModal ? "height: 708px;" : "height: 508px";
  }

  get isUsingDerivedStw(): boolean {
    return Fouling.foulingChartConfig.useDerivedStw;
  }

  // @Methods
  onDateRangeChanged(datepickerRef: string, date: string): void {
    (this.$refs[datepickerRef] as any).save(date);
    this.syncDateRange = [this.fromDate, this.toDate];
    const fromDate = moment(this.fromDate);
    const toDate = moment(this.toDate);
    const rangeYears = toDate.diff(fromDate, "years", true);
    if (rangeYears > 5) {
      this.invalidRange = true;
      return;
    }
    this.invalidRange = false;
    Vue.set(this.seaTemperatureDateRange, "from", fromDate.format("YYYY-MM-DD"));
    Vue.set(this.seaTemperatureDateRange, "to", toDate.format("YYYY-MM-DD"));

    const config = Fouling.foulingChartConfig;
    config.opFromDate = this.fromDate;
    config.opToDate = this.toDate;
    Fouling.updateFoulingChartConfig(config);

    this.Map.updateWeatherData(this.seaTemperatureDateRange);
  }

  onMarkerClicked(marker: MapMarker): void {
    if (this.syncSpeedLog) {
      this.$emit("markerClicked", marker);
      this.Map.map.fitBounds([[marker.position.lat, marker.position.lng]]);
    }
  }

  onTrackClicked(marker: MapMarker): void {
    if (this.syncSpeedLog) this.$emit("trackClicked", { trackDateStart: marker.trackHistory.prevMarkers.slice(-1)[0].lastExportDate, trackDateEnd: marker.trackHistory.prevMarkers[0].lastExportDate });
  }

  onMapZoom(minMaxDateRange: { min: number; max: number; zoomedOut: boolean }): void {
    if (this.syncSpeedLog) this.$emit("onOperationalProfileZoom", minMaxDateRange);
  }

  setDateRange(datesRange: { min: number; max: number }): void {
    this.fromDate = moment(datesRange.min).format("YYYY-MM-DD");
    this.toDate = moment(datesRange.max).format("YYYY-MM-DD");
    this.syncDateRange = [this.fromDate, this.toDate];
    Vue.set(this.seaTemperatureDateRange, "from", moment(this.fromDate).format("YYYY-MM-DD"));
    Vue.set(this.seaTemperatureDateRange, "to", moment(this.toDate).format("YYYY-MM-DD"));

    this.Map.$refs.mapRef.setZoom(1);
    this.Map.updateWeatherData(this.seaTemperatureDateRange);
  }

  expandMap(): void {
    this.$emit("onExpandMap", true);
  }

  created(): void {
    this.seaTemperatureDateRange = { from: this.syncDateRange[0], to: this.syncDateRange[1] };
    this.fromDate = moment(this.seaTemperatureDateRange.from).format("YYYY-MM-DD");
    this.toDate = moment(this.seaTemperatureDateRange.to).format("YYYY-MM-DD");
  }
}
